@mixin link($light, $dark) {
// CUSTOM
  .header-wrapper a {
    text-decoration: none;

    @if $light {
      color: $header-text-color;
    } @else {
      color: $header-text-color;
    }

    [theme=dark] & {
      @if $dark {
        color: $header-text-color-dark;
      } @else {
        color: $header-text-color-dark;
      }
    }
  }
  
// CUSTOM
  .header-wrapper a:active,
  .header-wrapper a:hover {
    font-weight: 900;

    @if $light {
      color: $header-text-hover-color;
    } @else {
      color: $header-text-hover-color;
    }

    [theme=dark] & {
      @if $dark {
        color: $header-text-hover-color-dark;
      } @else {
        color: $header-text-hover-color-dark;
      }
    }
  }

  a, a::before, a::after {
    text-decoration: none;

    @if $light {
      color: $global-link-color;
    } @else {
      color: $single-link-color;
    }

    [theme=dark] & {
      @if $dark {
        color: $global-link-color-dark;
      } @else {
        color: $single-link-color-dark;
      }
    }
  }

  a:active,
  a:hover {
    @if $light {
      color: $global-link-hover-color;
    } @else {
      color: $single-link-hover-color;
    }

    [theme=dark] & {
      @if $dark {
        color: $global-link-hover-color-dark;
      } @else {
        color: $single-link-hover-color-dark;
      }
    }
  }
}
