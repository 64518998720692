// **************
// This file overrides variables from LoveIt theme
// themes/LoveIt/assets/css/_variables.scss
// **************

// Light mode palette
// Primary #0485c7 
// Secondary #85C704
// Tretiary #C70485

// Light mode accessible
// Primary #1E5C8F
// #3F6215
// #8F1E5C

// Dark mode palette
// Primary 
// Secondary
// Tretiary 

// ========== Global ========== //
// Font and Line Height
//$global-font-family: system-ui, -apple-system, Segoe UI, Roboto, Emoji, Helvetica, Arial, sans-serif !default;
//$global-font-weight: 400 !default;
//$global-line-height: 1.5rem !default;
$global-font-size: 18px;

// Color of the background
//$global-background-color: #fff !default;
$global-background-color: #fafdff;
//$global-background-color-dark: #292a2d !default;

// Color of the text
$global-font-color: #05061A;
//$global-font-color-dark: #a9a9b3 !default;

// Color of the secondary text
$global-font-secondary-color: #05061A;
//$global-font-secondary-color-dark: #5d5d5f;

// Color of the link
$global-link-color: #05061A;
//$global-link-color-dark: #a9a9b3;

// Color of the hover link
$global-link-hover-color: #3F6215;
//$global-link-hover-color-dark: #fff;

// Color of the border
//$global-border-color: #f0f0f0;
//$global-border-color-dark: #363636;

// ========== Global ========== //

// ========== Scrollbar ========== //
// Color of the scrollbar
// $scrollbar-color: #87878d !default;

// Color of the hover scrollbar
// $scrollbar-hover-color: #a9a9b3 !default;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
// $selection-color: rgba(53, 166, 247, 0.25) !default;
// $selection-color-dark: rgba(50, 112, 194, 0.4) !default;
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
// $header-height: 3.5rem !default;

// Font family and size of the header title
// $header-title-font-family: $global-font-family !default;
// $header-title-font-size: 1.5rem !default;

// Color of the header background
// $header-background-color: #f8f8f8 !default;
$header-background-color: #1e5c8f;
// $header-background-color-dark: #252627 !default;

// Color of the hover header item
// $header-hover-color: #161209 !default;
//$header-hover-color: #cbe8fc;
// $header-hover-color-dark: #fff !default;

// Color of the header items
$header-text-color: #fafdff;
$header-text-color-dark: #a9a9b3;

// Color of the header hover items
$header-text-hover-color: #cbe8fc;
$header-text-hover-color-dark: #fff;

// Color of the search background
// $search-background-color: #e9e9e9 !default;
// $search-background-color-dark: #363636 !default;
// ========== Header ========== //

// ========== Single Content ========== //

// Font size of the TOC
//$toc-title-font-size: 1.2rem;
//$toc-content-font-size: 1rem;

// Color of the single link
$single-link-color: #1E5C8F;
//$single-link-color-dark: #05A6F9;

// Color of the hover single link
$single-link-hover-color: #3F6215;
//$single-link-hover-color-dark: #bdebfc;

// Color of the table background
//$table-background-color: #fff;
//$table-background-color-dark: #272c34;

// Color of the table thead
//$table-thead-color: #ededed;
//$table-thead-color-dark: #20252b;

// Color of the blockquote
//$blockquote-color: #6bd6fd;
//$blockquote-color-dark: #59c5ec;

// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: #05061A;
// $pagination-link-color-dark: #a9a9b3;

// Color of the hover link in pagination
$pagination-link-hover-color: #05061A;
// $pagination-link-hover-color-dark: #fff;
// ========== Pagination ========== //